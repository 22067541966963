
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressPackageDialogController', ExpressPackageDialogController);

    ExpressPackageDialogController.$inject = ['$scope', '$rootScope', '$timeout', '$http', '$uibModal', '$translate', '$uibModalInstance',
        'entity', 'ManageEnterprise', 'DataUtils', 'OpsVender', 'OpsCarrier', 'WmsWarehouse', 'DateUtils', 'WarehousingData', 
        'AddressBook', 'ExpressParcel', 'ExpressPackage'
    ];

    function ExpressPackageDialogController($scope, $rootScope, $timeout, $http, $uibModal, $translate, $uibModalInstance, 
        entity, ManageEnterprise, DataUtils, OpsVender, OpsCarrier, WmsWarehouse, DateUtils, WarehousingData, 
        AddressBook, ExpressParcel, ExpressPackage) {
        var vm = this;
        vm.bizType = entity.type;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.submitIn = submitIn;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.focusCargo = focusCargo;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.countrySelected = countrySelected;
        vm.getBarcode = getBarcode;
        vm.getShipment = getShipment;
        vm.getZoneName = getZoneName;
        vm.initRapidIn = initRapidIn;

        vm.clear = clear;

        $scope.guide = {};
        vm.thisCargo = {};
        vm.isSaving = false;
        vm.location = "";
        vm.zoneName = "";

        var initGuidePl = function() {
            if (!$scope.guide.expressPackages || $scope.guide.expressPackages.length == 0) {
                $scope.guide.expressPackages = [];
                vm.thisCargo = angular.copy(newCargo);
                vm.thisCargo.pkgs = $scope.guide.pkgNum;
                vm.thisCargo.gw = $scope.guide.gw;
                vm.thisCargo.vol = $scope.guide.vol;
                $scope.guide.expressPackages.push(vm.thisCargo);
            }
            else {
                vm.thisCargo = $scope.guide.expressPackages[0];
            }
            angular.forEach($scope.guide.expressPackages, function(data){
                if (data.pkgs) {
                    if (data.gw) {
                        data.gwTtl = DataUtils.round(data.pkgs * data.gw, 2);
                    }
                    if (data.vol) {
                        data.volTtl = DataUtils.round(data.pkgs * data.vol, 5);
                    }
                }             
            })        

            if(!$scope.guide.cargoType){
                $scope.guide.cargoType = "generalCargo";
            }
            if(!$scope.guide.shiptoCountryCode){
                $scope.guide.shiptoCountryCode = "US";
                $scope.guide.destCountryName = "美国";
            }
            else{
                $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.shiptoCountryCode);
            }
            if(!$scope.guide.shipmentType){
                $scope.guide.shipmentType = vm.bizType == "PARCEL"?"PARCEL":"LTL";
            }    
            vm.pkgsTtl = $scope.guide.pkgNum;

            $timeout(function() {
                $("#gw_field").focus();
                $("#gw_field").select();
            });            
            getZoneName();
        };

        function getZoneName() {
            if (!$scope.guide.shiptoPostalCode) {
                return;
            }
            vm.zoneName = "";
            ExpressParcel.getZoneName($scope.guide, function(result){
                vm.zoneName = result.zoneName;
            })
        }

        function getShipment() {
            if (!$scope.guide.jobNum) {
                return;
            }
            vm.isSaving = true;
            ExpressParcel.getRapidInParcel({
                jobNum: $scope.guide.jobNum,
                byPackage: true
            }, function(result) {
                $scope.guide = result;
                initGuidePl();
                vm.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, function(result){
                vm.isSaving = false;
                layer.msg($translate.instant('global.loadFailed'));
            })
        }

        $timeout(function() {
            if($rootScope.account.authorities.indexOf('PARCEL') === -1 
                && $rootScope.account.authorities.indexOf('TMS') === -1){
                layer.msg($translate.instant('global.forbidden'));
                clear();
            }            
            if(entity && entity.parcelId){
                ExpressParcel.get({
                    id: entity.parcelId
                }, function(result) {
                    $scope.guide = result;
                    initGuidePl();
                });
            }         
            $timeout(function() {
                $("#job_num_field").focus();
            });
            websockSubscribe();
        });

        function websockSubscribe () {
            if(!$rootScope.account.companyId || $rootScope.HAVENOAUTH('GOODSCAN')){
                layer.msg("No binded equipment..")
                return;
            }
            WarehousingData.websockSubscribe({
                userId: $rootScope.account.id,
                companyId: $rootScope.account.companyId
            }, function (argument) {
                layer.msg("Connected with equipment..")
            })          
        }

        function clear () {
            $uibModalInstance.close();                     
        }
        function getBarcode (obj, fd) {
            if(obj.locked){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (fd == "code"){

                } else {
                    obj[fd] = code;
                }
                if(fd =="jobNum"){
                    getShipment();
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function countrySelected (item) {
            $scope.guide.shiptoCountryCode = item.code;
        }


        var newCargo = {packageType:"CTNS", pkgs:1};
        function initRapidIn() {
            $scope.guide = {
                createdBy: $rootScope.account.id,
                pkgNum: 1,
                shipmentType: vm.bizType == "PARCEL"?"EXPRESS":"LTL",
                cargoType: "generalCargo",
                shiptoCountryCode: "US",
                destCountryName: $rootScope.getCountryName("US"),
                status: "wmsIn",
                expressPackages: []
            }
            vm.thisCargo= angular.copy(newCargo);     
            $scope.guide.expressPackages.push(vm.thisCargo);
            getWarehouses();
            $timeout(function() {
                $("#job_num_field").focus();
            });
        }
        initRapidIn();       

        KEYBOARD = "parcelWmsIn";
        $(document).keydown(function(event) {            
            if( KEYBOARD != "parcelWmsIn"){
                return;
            }
            else if (event.keyCode == 81 && event.ctrlKey) {
                event.preventDefault();
                initRapidIn();               
            } 
        });

        function addCargo() {
            if (!$scope.guide.expressPackages) {
                $scope.guide.expressPackages = [];
            }
            updateTtl();
            if(vm.pkgsTtl && $scope.guide.pkgNum >= vm.pkgsTtl){
                $rootScope.ALERT_ALARM();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', {actAmt: $scope.guide.pkgNum, limit: vm.pkgsTtl}), {icon: 0, shade: 0.3})
                return;
            }

            vm.thisCargo= angular.copy(newCargo);       
            $scope.guide.expressPackages.push(vm.thisCargo);   
            $timeout(function() {
                $("#gw_field").focus();
            });
        }

        function focusCargo(cargo) {
            vm.thisCargo = cargo;
        }
        function delCargo(cargo) {
            if(cargo.id){
                ExpressPackage.delete({
                    id: cargo.id
                }, function(){
                    delCargoFmList(cargo);
                });
            }
            delCargoFmList(cargo);
        }
        function delCargoFmList(cargo) {
            var index = $scope.guide.expressPackages.indexOf(cargo);
            if (index > -1) {
                $scope.guide.expressPackages.splice(index, 1);
            };
            updateTtl();
        }


        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                if(!$scope.guide.warehouseId){
                    warehouseSelected(vm.warehouses[0]);
                }    
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.warehouses = data;
                if(vm.warehouses.length > 0 && !$scope.guide.warehouseId){
                    warehouseSelected(vm.warehouses[0]);
                }                
            });
        }
        getWarehouses();

        function warehouseSelected (warehouse) {
            $scope.guide.warehouseName = warehouse.name;
            $scope.guide.warehouseId = warehouse.id;
            $scope.guide.companyId = warehouse.companyId;
        }

        function submitIn() {
            $scope.guide.pkgNum = 0;
            for(var i = $scope.guide.expressPackages.length  -1; i >= 0; i--){
                if(!$scope.guide.expressPackages[i].id){
                    if(!$scope.guide.expressPackages[i].pkgs){
                        $scope.guide.expressPackages.splice(i, 1);
                    }
                    else if (!$scope.guide.expressPackages[i].sku 
                        && !$scope.guide.expressPackages[i].cargoName 
                        && !$scope.guide.expressPackages[i].gw
                        && !$scope.guide.expressPackages[i].length
                        && !$scope.guide.expressPackages[i].width
                        && !$scope.guide.expressPackages[i].height
                        && !$scope.guide.expressPackages[i].vol){
                        $scope.guide.expressPackages.splice(i, 1);
                    }     
                }
            }
            vm.isSaving = true;
            ExpressParcel.parcelPackageSave($scope.guide, onSaveSuccess, onSaveError);          
        }
        function onSaveSuccess (result) {
            $scope.guide = result;
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            // initRapidIn();
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('autopsApp.wmsIn.jobNumConflictAlert'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            } 
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        }


        function updateTtl (isPkg) {
            $scope.guide.gw = 0;
            $scope.guide.vol = 0;
            $scope.guide.pkgNum = 0;

            angular.forEach($scope.guide.expressPackages, function(data){
                if(!data.pkgs){
                    data.pkgs = 0;
                }
                $scope.guide.pkgNum += data.pkgs;
                if(data.gw){
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);  
                    $scope.guide.gw += data.gw * data.pkgs;
                }
                if(data.vol){
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);  
                    $scope.guide.vol += data.vol * data.pkgs;
                }
            })
            $scope.guide.gw = DataUtils.round($scope.guide.gw, 2);
            $scope.guide.vol = DataUtils.round($scope.guide.vol, 4);

            if(isPkg && vm.pkgsTtl && $scope.guide.pkgNum >= vm.pkgsTtl){
                $rootScope.ALERT_ALARM();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', {actAmt: $scope.guide.pkgNum, limit: vm.pkgsTtl}), {icon: 0, shade: 0.3})
            }
        }
        
        function updateVol (cargo) {
            if (cargo.length && cargo.width && cargo.height){
                cargo.vol = cargo.length * cargo.width * cargo.height/1000000;
                cargo.vol = DataUtils.round(cargo.vol, 4);
                updateTtl();
            }
        }

        $(document).keydown(function(event) {            
            if (event.keyCode == 13) {
                if ("gw_field" == document.activeElement.id) {
                    $("#field_length").focus();
                }
                else if ("field_length" == document.activeElement.id) {
                    $("#field_width").focus();
                }
                else if ("field_width" == document.activeElement.id) {
                    $("#field_height").focus();
                }                
                else if ("field_height" == document.activeElement.id) {
                    $timeout(function (){
                        $("#button_addCargo").focus();
                    });
                }
            } 
        });
        $(document).keydown(function(event) {            
            if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                submitIn();              
            } 
            else if (event.ctrlKey && (event.keyCode == 13 || event.keyCode == 107)) {
                event.preventDefault();
                addCargo();  
            }                    
        });

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    vm.isSaving = false;
                });

            };
        };
        $scope._onAddressSelected = function(data, type) {
            if(type == "thirdparty"){
                $scope.guide.accountId = data.id;
            }
            else if(type == "shipfrom"){
                $scope.guide.shipFromId = data.id;
                $scope.guide.shipperCompanyName = data.companyName;

                if(data.addressOne){
                    $scope.guide.shipperAddressLineOne = data.addressOne.substring(0, 35);
                    var len = data.addressOne.length;
                    if(len > 70){
                        $scope.guide.shipperAddressLineThree = data.addressOne.substring(70, 105);
                    }
                    if(len > 35){
                        $scope.guide.shipperAddressLineTwo = data.addressOne.substring(35, 70);
                    }
                }
                if(data.addressTwo){
                    $scope.guide.shipperAddressLineTwo = data.addressTwo.substring(0, 35);
                }
                if (data.addressThree){
                    $scope.guide.shipperAddressLineThree = data.addressThree.substring(0, 35);
                }

                $scope.guide.shipperAttentionName = data.attn;
                $scope.guide.shipperPostalCode = data.postCode;
                $scope.guide.shipperCountryCode = data.countryCode;
                $scope.guide.shipperProvinceCode = data.province;
                $scope.guide.shipperCity = data.city;
                $scope.guide.shipperPhoneNumber = data.tel;
                $scope.guide.shipperEmailAddress = data.email;
                $scope.guide.shipperTaxId = data.taxId;
                $scope.guide.labelBindType = data.bindType;
            }
            else if (type == 'shipto'){
                $scope.guide.shipToId = data.id;
                $scope.guide.shiptoCompanyName = data.companyName;

                if(data.addressOne){
                    $scope.guide.shiptoAddressLineOne = data.addressOne.substring(0, 35);
                    var len = data.addressOne.length;
                    if(len > 70){
                        $scope.guide.shiptoAddressLineThree = data.addressOne.substring(70, 105);
                    }
                    if(len > 35){
                        $scope.guide.shiptoAddressLineTwo = data.addressOne.substring(35, 70);
                    }
                }  
                if(data.addressTwo){
                    $scope.guide.shiptoAddressLineTwo = data.addressTwo.substring(0, 35);
                }
                if (data.addressThree){
                    $scope.guide.shiptoAddressLineThree = data.addressThree.substring(0, 35);
                }
                $scope.guide.shiptoAttentionName = data.attn;
                $scope.guide.shiptoPostalCode = data.postCode;
                $scope.guide.shiptoCountryCode = data.countryCode;
                $scope.guide.shiptoProvinceCode = data.province;
                $scope.guide.shiptoCity = data.city;
                $scope.guide.shiptoPhoneNumber = data.tel;
                $scope.guide.shiptoEmailAddress = data.email;
                autoRemoteCheck();
            }
            else {                
                if(type == 'ownImporterId' && data.countryCode != $scope.guide.shiptoCountryCode){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.importerCountryDiffAlert'));
                    return; 
                }
                $scope.guide[type] = data.id;
            }
        }

        var autoRemoteCheck = function(){
            if(!$scope.guide.shiptoCountryCode || !$scope.guide.shiptoPostalCode 
                || !$scope.guide.carrier){
                return;
            }
            ExpressParcel.remoteCheck({
                country: $scope.guide.shiptoCountryCode,
                postcode: $scope.guide.shiptoPostalCode,
                carrier: $scope.guide.carrier
            }, function(results){
                if(results && results.length > 0){
                    $uibModal.open({
                        templateUrl: 'app/entities/sub-tracking/sub-tracking-dialog.html',
                        controller: 'SubTrackingDialogController',
                        controllerAs: 'vm',
                        backdrop: false,
                        size: 'md',
                        resolve: {
                            entity: [function() {
                                return {
                                    results: results
                                };
                            }]
                        }
                    }).result.then(function() {
                    }, function() {
                    });
                }
                else {
                    layer.msg($translate.instant('global.notRemoteMsg'));
                }
            }, function () {
            });
        }

        
        $scope.gwTtlChange = function(data) {
            data.gw = DataUtils.round(data.gwTtl / data.pkgs, 2);
            $scope.guide.gw = 0;
            angular.forEach($scope.guide.expressPackages, function(data){
                if(data.gwTtl){
                    $scope.guide.gw += data.gwTtl;
                }
            })
            $scope.guide.gw = DataUtils.round($scope.guide.gw, 2);
        };

        $scope.volTtlChange = function(data) {
            data.vol = DataUtils.round(data.volTtl / data.pkgs, 2);
            $scope.guide.vol = 0;
            angular.forEach($scope.guide.expressPackages, function(data){
                if(data.volTtl){
                    $scope.guide.vol += data.volTtl;
                }
            })
            $scope.guide.vol = DataUtils.round($scope.guide.vol, 2);
        };

        $scope.onCarrierSelected = function(carrier) {
            $scope.guide.carrierCode = carrier.code;
        }
        $scope.onClientSelected = function(data) {
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (!result.audited && result.clientMustAudit) {
                    $scope.guide.shipperName = null;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                $scope.guide.shipperId = result.venderId;
                $scope.guide.shipperName = result.companyName;
                $scope.guide.shipperUserId = result.userId;
            });
        }
        $scope.printLabel = function(expressParcelId) {
            layer.msg("Generating label...", {
              shade: 0.3,
              time: 200000
            });
            vm.isSaving = true;
            ExpressParcel.generateOneLabel({id: expressParcelId}, function(result) {
                layer.closeAll();
                vm.isSaving = false;
                $rootScope.PDFPOP(result.labelUrl, "label");
            }, function(error){
                layer.closeAll();
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }  
            });
        }
        var unsubscribe = $rootScope.$on('autopsApp:expressPackageReceived', function(event, result) {
            if(!$scope.guide.id || $scope.guide.id != result.expressId){
                return;
            }
            var isNew = true;
            angular.forEach($scope.guide.expressPackages, function(data){
                if(data.id == result.id){
                    isNew = false;
                }
            })
            if(isNew){
                $scope.guide.expressPackages.splice(0, 0, result);
                updateTtl(true);
            }
        });
        $scope.$on('$destroy', unsubscribe);
        $scope._onChannelSelected = function(data) {
            $scope.guide.channel = data;
            layer.load(1, {shade: 0.3});
            ExpressParcel.updateByChannel($scope.guide, function(result) {
                layer.closeAll();
                $scope.guide = result;
            }, function(){
                layer.closeAll();
            });
        }
    }
})();